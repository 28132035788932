<!--
  PACKAGE_NAME : src\pages\esp\system\server\host.vue
  FILE_NAME : host
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 라우팅 연결 서버 정보
-->
<template>
  <div>
    <DxPopup
      :ref="'routingHostPopup'"
      :visible="isOpen"
      :title="routingHostPopup.title"
      :show-title="true"
      :min-width="routingHostPopup.minWidth"
      :width="routingHostPopup.width"
      :min-height="routingHostPopup.minHeight"
      :height="routingHostPopup.height"
      :resize-enabled="false"
      :drag-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      @hiding="closeModal"
    >
      <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="2" :disabled="true">
        <Tab title="호스트 관리">
          <esp-dx-data-grid :data-grid="hostGrid" :ref="hostAliasGrid.refName" v-if="tabIndex === 0" @init-new-row="onInitNewRowToHost" />
        </Tab>
        <Tab title="별칭 관리">
          <esp-dx-data-grid :data-grid="hostAliasGrid" :ref="hostAliasGrid.refName" v-if="tabIndex === 1" @init-new-row="onInitNewRowToAlias" />
        </Tab>
      </Tabs>
    </DxPopup>
  </div>
</template>

<script>
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import { DxPopup } from 'devextreme-vue/popup';
  import ArrayStore from 'devextreme/data/array_store';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, DxPopup, Tabs, Tab },
    props: {
      isOpen: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        tabIndex: 0,
        routingHostPopup: {
          title: '라우팅 호스트 정보',
          minWidth: 400,
          width: 850,
          minHeight: 300,
          height: 700,
        },
        hostGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'refHostGrid', // 그리드 컴포넌트 참조명
          excel: {
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          // height: '620', // 주석처리시 100%
          apiActionNm: {
            merge: 'ROUTING_HOSTS_MERGE',
            delete: 'ROUTING_HOSTS_DELETE',
          }, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'virtual', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            initNewRow: true, // 신규 행 추가 이벤트
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            delete: true,
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: true, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '호스트',
              dataField: 'host',
              visible: true,
              alignment: 'center',
              allowEditing: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              requiredRule: {
                message: '호스트는 필수입니다.',
              },
            },
            {
              caption: '별칭',
              dataField: 'alias',
              visible: true,
              alignment: 'center',
              allowEditing: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              width: '150',
              lookup: {
                dataSource: [],
                displayExpr: 'label',
                valueExpr: 'value',
              },
              requiredRule: {
                message: '별칭은 필수입니다.',
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'left',
              visible: true,
              allowEditing: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              requiredRule: {
                message: '호스트 설명은 필수입니다.',
              },
            },
          ], // 컬럼 정보
        },
        hostAliasGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'refHostAliasGrid', // 그리드 컴포넌트 참조명
          excel: {
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          // height: '620', // 주석처리시 100%
          apiActionNm: {
            merge: 'ROUTING_ALIAS_MERGE',
          }, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'virtual', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            initNewRow: true, // 신규 행 추가 이벤트
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            delete: false,
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'single', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'none', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: true, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '별칭',
              dataField: 'alias',
              visible: true,
              alignment: 'center',
              allowEditing: true,
              allowHeaderFiltering: true,
              requiredRule: {
                message: '별칭을 필수입니다.',
              },
            },
            {
              caption: '사용여부',
              dataField: 'useFl',
              visible: true,
              alignment: 'center',
              allowEditing: true,
              lookup: {
                dataSource: [
                  { value: 'Y', label: '사용' },
                  { value: 'N', label: '미사용' },
                ],
                valueExpr: 'value',
                displayExpr: 'label',
              },
              requiredRule: {
                message: '사용여부는 필수입니다.',
              },
            },
          ], // 컬럼 정보
        },
      };
    },
    methods: {
      async tabSelectedIndex(index) {
        this.tabIndex = index;
      },
      /**
       * 팝업 닫기
       */
      closeModal() {
        this.$emit('closeModal');
      },
      /**
       * 호스트 그리드 신규 행 초기화
       * @param e
       */
      onInitNewRowToHost(e) {
        e.data.host = 'http://';
      },
      /**
       * 별칭 그리드 신규 행 초기화
       * @param e
       */
      onInitNewRowToAlias(e) {
        e.data.useFl = 'Y';
      },
      /**
       * 데이터 초기화
       */
      initData() {
        this.setRoutingAliases();
      },
      /**
       * 라우팅 별칭 목록 조회
       * @returns {Promise<void>}
       */
      async setRoutingAliases() {
        const payload = {
          actionname: 'ROUTING_ALIAS_LIST',
          loading: false,
        };
        const res = await this.CALL_API(payload);
        let aliases = [];
        if (isSuccess(res)) {
          res.data.data.forEach(item => {
            aliases.push({
              label: item.alias,
              value: item.alias,
            });
          });
        } else {
          aliases = [];
        }

        this.hostGrid.columns[1].lookup.dataSource = new ArrayStore({
          data: aliases,
          key: 'value',
        });
      },
      /**
       * 호스트 목록 조회
       * @return {Promise<{data, totalCount}>}
       */
      async selectHostList() {
        let vm = this;
        this.hostGrid.dataSource = new CustomStore({
          key: 'id', // store id 설정필요
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params };

            const payload = {
              actionname: 'ROUTING_HOSTS_LIST',
              data: params,
              loading: false,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              return {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            } else {
              this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          },
          insert(values) {
            // insert
          },
          update(key, values) {
            // update
          },
          totalCount: () => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /**
       * 별칭 목록 조회
       * @return {Promise<{data, totalCount}>}
       */
      async selectAliasList() {
        let vm = this;
        this.hostAliasGrid.dataSource = new CustomStore({
          key: 'id', // store id 설정필요
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params };

            const payload = {
              actionname: 'ROUTING_ALIAS_LIST',
              data: params,
              loading: false,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              return {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            } else {
              this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          },
          insert(values) {
            // insert
          },
          update(key, values) {
            // update
          },
          totalCount: () => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
    },
    created() {
      this.initData();
    },
    mounted() {
      this.selectHostList(); // 호스트 목록 조회
      this.selectAliasList(); // 별칭 목록 조회
    },
  };
</script>

<style lang="scss" scoped></style>